.main-img-partners {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  margin-bottom: 40px;
  justify-content: space-around;
}

.main-img-partners-each {
  margin: auto;
  height: 140px;
}

.main-img-partners-each a {
  color: inherit;
  text-decoration: none;
}

.main-img-partners-each img {
  width: 150px;
  height: auto;
  box-sizing: border-box;
  margin: 15px;
  transition: transform 0.5s ease;
}

.main-img-partners-each img:hover {
  transform: scale(1.05);
}

.partners-name {
  text-align: center;
}

.main-img {
  display: flex;
  width: 90%;
  height: auto;
  margin: auto;
  position: relative;
  margin-bottom: 30px;
}

.text-left {
  left: 0;
}

.text-right {
  right: 0;
}

.h2 {
  margin: auto;
  text-align: center;
  margin-bottom: 20px;
  /* background-image: linear-gradient(90deg, #e9e8e8, #f8f8f8); */
  font-size: 43px;
  border-radius: 9px;

  color: black;
}

.partners {
  display: flex;
  margin: auto;
}

.partners img {
  width: 180%;
  height: 110%;
}

.each-img-partners {
  overflow: hidden;
  width: 50vh;
  height: 50vh;
  margin: auto;
  border: 5px solid transparent;
  position: relative;
}

@media only screen and (max-width: 500) {
  .main-img-partners-each img {
    width: 120px;
  }
}
