.h2 {
  margin: auto;
  text-align: center;
  margin-bottom: 20px;

  border-radius: 9px;
  word-spacing: 10px;
  color:white;
}

.container-about {
  margin: auto;
  padding: 100px;
  width: 100%;
  box-sizing: border-box;
}




.about-text {
  font-size: 22px;
  line-height: 50px;
  margin-bottom: 30px;
}

.about-section{
  width: 70%;
  margin: auto;
  padding: 30px 30px 5px 30px;  
  left:11%;
  color: black;
  background: rgba(255, 255, 255, 0.3)
}
