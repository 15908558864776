.contact-list {
  display: inline-block;
  list-style-type: none;
  margin-left: 50px;
  width: 100vh;
  height: 55vh;
  line-height: 50px;
  font-size: 19px;
}

.map {
  width: 500px;
  height: 500px;
  float: right;
  top: 20px;
  right: 20px;
  position: absolute;
}

.contact-map {
  width: 100%;
  height: 100%;
}

.container-contacts {
  position: relative;
  width: 50%;
  margin: 5px;
  padding: 15px;
}

.container-contactsMap {
  position: relative;
  width: 50%;
  margin: 5px;
}

.mainContainer-contacts {
  display: flex;
  margin: 16px;
}
