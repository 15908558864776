.pagination {
  display: flex;
  list-style-type: none;
}

.page-item {
  width: 19px;
  height: 14px;
  margin: 7px;
  /* -webkit-box-shadow: -1px 2px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 2px 5px 0px rgba(0, 0, 0, 0.75); */
  /* box-shadow: -1px 2px 5px 0px rgba(0, 0, 0, 0.75); */
  cursor:pointer;
}

.page-item:active {
  transform: scale(0.96);
}

.page-link {
  text-decoration: none;
  color: black;
  margin: 3px;
}
