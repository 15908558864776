.img-section-projects {
  flex-wrap: wrap;
  width: 70%;
  height: auto;
  text-align: center;
  display: flex;
  position: relative;
  margin: auto;
}

.custom-select{
  margin: 20px;
  width:auto;
}

.select{
  width: 120px;
  height: 30px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  outline: none;
}

.select-option{
  border: 1px solid white;
}

