.modal-img{
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    width: 55vw;
    height: auto;
    margin: 0 auto;
}

.modal-div{
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.button{
    text-decoration: none;
    width:auto;
    height: 40px;
    padding:5px;
    background-color: white;
    cursor: pointer;
    border-radius: 10px;
    border:none;
    margin:10px;
    box-shadow: none;
    font-weight: bold;
    font-size: 16px;
}

.button:hover{
    transform: scale(1.5);
}

.close-button-div{
    position: fixed;
    right:70px;
}