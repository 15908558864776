footer {
  background-color: #243f54;
  color: white;
}

.footer-list {
  margin-top: 10px;
  display: flex;
  flex-wrap: nowrap;
  margin-left: 5px;
  list-style-type: none;
}

.footer-list li {
  margin-right: 10px;
}

.main-footer {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.main-footer li {
  list-style-type: none;
}

#nav-footer {
  visibility: visible;
  display: flex;
  width: 150vh;
  margin: auto;
  bottom: 10px;
  right: 100px;
  z-index: 101;
  padding: 5px;
}

.underline-footer {
  font-size: 20px;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 10px;
}

.rights {
  margin: auto;
  width: 80%;
  text-align: center;
  padding: 15px;
  border-top: 1px solid white;
}

.footer-rekvizitai {
  font-size: 2.5vh;
  letter-spacing: 1px;
}

.footer-rekvizitai li {
  padding: 10px;
}

.column li {
  margin: 0 30px 40px 0;
}

.column {
  width: 100%;
}

.menu-columns {
  display: flex;
  padding-top: 50px;
  width: 50%;
}

@media only screen and (max-width: 900px) {
  #nav-footer {
    flex-direction: column !important;
    text-align: center;
    width: inherit;
  }

  .main-footer {
    width: 100%;
  }

  .menu-columns {
    display: unset;
    margin: auto;
  }
}
