* {
  margin: 0;
  padding: 0;
  font-family: "Mulish", sans-serif;
}

html {
  margin: auto;
}

main {
  width: 90%;
  height: 100%;
  margin: auto;
}

body {
  position: relative;
  background-color: white;
}

.flags a {
  padding-left: 5px;
}

header {
  width: 100%;
  height: 10vh;
  margin-bottom: 15px;
  position: relative;
  /* background-image: linear-gradient(90deg, #dfdfdf, #f8f8f8); */
  background-color: white;
  color: black;
}

.header-list {
  margin: 0 0 0 30px;
  display: flex;
  flex-wrap: nowrap;
  list-style-type: none;
}

.header-list li {
  margin-right: 10px;
}
.scrolled {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  background-color: #243f54;
  height: 70px;
}

.scrolled-list {
  top: 60px;
}

#nav-list {
  background-color: white;
  color: black;
}

#nav {
  visibility: visible;
  display: flex;
  justify-content: space-around;
  width: 70%;
  margin: auto;
  position: absolute;
  top: 20px;
  right: 200px;
  z-index: 101;
}

.underline {
  list-style-type: none;
  cursor: pointer;
  font-size: 23px;
}

.underline::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #000;
  transition: width 0.3s;
}

.underline:hover::after {
  width: 100%;
  /* transition: width .3s; */
}

.links {
  text-decoration: none;
  color: inherit;
}

/* CONTAINER */

.text-container-glass {
  background-image: url("../images/gradient.svg");
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.9;
  width: 100%;
  height: 30px;
  position: absolute;
  bottom: 0px;
  box-shadow: 0 10px 16px 0 rgba(45, 0, 247, 0.2);
}

.logo {
  width: 200px;
}

.flags {
  padding-right: 15px;
  padding-top: 10px;
  position: absolute;
  right: 20px;
  top: 0;
}

.flag_icon {
  width: 20px;
  background-repeat: no-repeat;
  background-color: white;
  border: none;
  cursor: pointer;
  margin: 5px;
}

.visiprojektai {
  display: flex;
  float: right;
  margin: 15px;
  height: 50px;
  width: 100%;
}

.visiprojektai p {
  color: black;
  font-size: 30px;
  text-decoration: none;
}

.header-top-bit {
  width: 100%;
  height: 20px;
  background-color: green;
}

#menuLogo {
  visibility: hidden;
}

@media only screen and (max-width: 800px) {
  .each-img {
    width: 200px;
    height: 200px;
  }

  .each-img-partners {
    width: 200px;
    height: 100px;
  }

  .nav.sticky {
    height: 10vh;
  }

  .underline {
    font-size: 13px;
  }

  .logo {
    width: 80px;
  }
}

@media only screen and (max-width: 759px) {
  .nav.sticky {
    height: 10vh;
  }

  .logo {
    visibility: hidden;
  }
}

@media only screen and (max-width: 800px) {
  .menu_logo {
    cursor: pointer;
    visibility: visible;
  }

  .menu_logo:active {
    transform: scale(0.95);
  }

  .underline {
    font-size: 13px;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 30px;
  }

  .underline::after {
    transition: none;
  }

  .header-list {
    font-size: 13px;
  }

  .header-logo {
    width: 15px;
  }

  header {
    background-color: white;
  }

  #nav-list {
    background-color: white;
  }

  #nav {
    position: relative !important;
    left: 0 !important;
    top: 0;
    margin: 0 !important;
    flex-direction: column;
    z-index: 100;
    width: 100%;
    height: 300px;
    background-color: #243f54;
    display: none;
    justify-content: flex-start;
    text-align: center;
    color: white;
  }

  .nav:active {
    -webkit-transition: width 0.5s;
    transition: width 0.5s;
  }

  .scrolled {
    position: unset;
    width: 100%;
    z-index: 101;
    background-color: white;
    height: 70px;
    top: 0;
  }

  .main-img {
    display: none;
  }
}

@media only screen and (max-width: 1018px) {
  .map {
    display: none;
  }
}

@media only screen and (max-width: 1240px) {
  .underline {
    font-size: 16px;
    margin-left: 25px;
  }

  .header-list {
    font-size: 16px;
  }

  .header-logo {
    width: 15px;
  }
}
